import { render, staticRenderFns } from "./AmcModal.vue?vue&type=template&id=89e0e952&scoped=true&"
import script from "./AmcModal.vue?vue&type=script&lang=js&"
export * from "./AmcModal.vue?vue&type=script&lang=js&"
import style0 from "./AmcModal.vue?vue&type=style&index=0&id=89e0e952&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89e0e952",
  null
  
)

export default component.exports